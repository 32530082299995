import React from 'react';
import logo from './vezpIcon.png';
import './App.css';
import SocialFollow from "./SocialFollow";  
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>
          VEZP
          <br></br>
          Be Where You Are
        </h2>
        <p style={{margin: 15}}>
          Explore the new social media app designed to connect you through shared experiences.
        </p>
        <p style={{margin: 15}}>
          With the new VEZP app, you can connect with people who attend the same events as you. Create a new event or access an existing event going on nearby to post and interact with other eventgoers.
        </p>
        <br></br>
        <SocialFollow />
        <h3>
          Coming Soon
        </h3>
        <br></br>
        <p>
          <a href='/termsOfService'>Terms of Service</a>
          {' '}
          <Link to="/eula">EULA</Link>
          {' '}
          <Link to="/privacyPolicy">Privacy Policy</Link>
        </p>
      </header>
    </div>
  );
};
  
export default Home;