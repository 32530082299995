import React from 'react';

var privacyPolicy = `At Vezp, we understand and value the importance of protecting your personal information. This Privacy Policy outlines the types of personal information we collect, how we use and protect it, and your rights with respect to your personal information.

\nCollection of Personal Information:

\n- We collect information such as your name, email address, and phone number when you create an account with Vezp.
\n- Information about your location, such as GPS data, may be collected when you use Vezp's location-based features.
\n- We collect information about your interactions with other users, such as messages and content you share.

\nUse of Personal Information:

\n- We use your personal information to provide and improve our services, such as to verify your identity and to personalize your experience.
\n- We may use your personal information for research and analytical purposes to better understand our users and improve our services.
\n- Your personal information may be used for marketing and promotional purposes, such as to send you updates and offers from Vezp.

\nProtection of Personal Information:

\n- We employ a variety of security measures, such as encryption and secure servers, to protect your personal information.
\n- We limit access to your personal information to only those employees who need it to perform their job duties.
\n- Despite these efforts, we cannot guarantee the security of your personal information, and you provide it at your own risk.

\nSharing of Personal Information:

\n- We will not sell or rent your personal information to third parties for their own marketing purposes without your explicit consent.
\n- We may share your personal information with third-party service providers for the purpose of providing our services.
\n- In certain cases, we may be required to share your personal information with law enforcement or other government authorities.

\nYour Rights:

\n- You have the right to access and control your personal information, including the right to request deletion of your account and personal information.
\n- If you are a resident of a country with data protection laws, you may have additional rights with respect to your personal information.

\nChanges to Privacy Policy:

\n- We may update this Privacy Policy from time to time to reflect changes to our practices or applicable laws.
\n- Your continued use of Vezp after any changes to this Privacy Policy constitutes your acceptance of the new terms.

\nContact Us:

\n- If you have any questions or concerns about our privacy practices, please contact us at vezpapp1@gmail.com`;

const Privacy = () => {
  return (
    <div style={{ margin: '20px' }}>
      <h1>Privacy Policy</h1>
      <p>
        <div style={{whiteSpace: 'pre-line'}}>{privacyPolicy}</div>
      </p>
    </div>
  );
};

export default Privacy;
