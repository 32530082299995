import React from 'react';
import './App.css';
import EULA from './pages/eula';
import Privacy from './pages/privacy';
import TermsOfService from './pages/terms';
import Home from './home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/eula" element={<EULA />} />
        <Route path="/privacyPolicy" element={<Privacy />} />
        <Route path="/termsOfService" element={<TermsOfService />} />
      </Routes>
    </Router>
  );
}

export default App;
