import React from 'react';

const termsOfService = `Welcome to Vezp, a social media platform provided by Vezp (“Vezp,” “we,” or “us”). By using Vezp, you agree to be bound by the following terms and conditions (the “Terms of Service” or “Agreement”). Please read this Agreement carefully.

\n1. Account Creation and Eligibility:
\n    - You must be at least 13 years of age to use Vezp.
\n    - When you create an account, you represent and warrant that the information you provide is accurate and complete.
\n    - You are responsible for maintaining the confidentiality of your account and password, and you accept responsibility for all activities that occur under your account.

\n2. User Conduct:
\n    - You agree not to use Vezp for any illegal or unauthorized purpose.
\n    - You agree not to use Vezp to infringe the intellectual property rights of others, such as by uploading copyrighted material without permission.
\n    - You agree not to use Vezp to post or transmit any harmful, threatening, abusive, harassing, defamatory, obscene, or otherwise offensive content.
\n    - You agree not to use Vezp to engage in any spamming or phishing activities.
\n    - You agree to comply with all local, state, national, and international laws and regulations.

\n3. Content and Intellectual Property:
\n    - You retain ownership of any content you submit to Vezp, but you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, copy, modify, and distribute that content in connection with Vezp.
\n    - You represent and warrant that you have the right to grant the license described above, and that your content does not infringe the intellectual property rights of any third party.

\n4. Disclaimer of Warranties:
\n    - Vezp is provided on an “as is” and “as available” basis, and we make no representations or warranties of any kind, express or implied, as to the operation of Vezp or the information, content, materials, or products included on Vezp.
\n    - We do not warrant that Vezp will be uninterrupted or error-free, and we will not be liable for any interruptions or errors.

\n5. Limitation of Liability:
\n     -We will not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with Vezp, even if we have been advised of the possibility of such damages.
\n    - Our total liability to you for all damages, losses, and causes of action arising out of or in connection with Vezp will not exceed the amount you have paid to us, if any, in the past 12 months.

\n6. Indemnification:
\n    - You agree to indemnify and hold us harmless from and against any and all claims, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of Vezp.

\n7. Termination:
\n    - We reserve the right to terminate your account or your use of Vezp at any time, for any reason, and without notice.
\n    - Upon termination, your right to use Vezp will immediately cease, and we may, but are not obligated to, delete all of your content and information.

\n8. Disputes:
\n    - This Agreement will be governed by and construed in accordance with the laws of the State of Colorado, without giving effect to its conflict of laws provisions.`;

const TermsOfService = () => {
  return (
    <div style={{ margin: '20px' }}>
      <h1>Terms of Service</h1>
      <p>
        <div style={{whiteSpace: 'pre-line'}}>{termsOfService}</div>
      </p>
    </div>
  );
};

export default TermsOfService;
